import React from "react";
import {
  FileOutlined,
  AppstoreOutlined,
  MoneyCollectOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";

import NotFoundPage from "pages/404Page";
import AppLayout from "layouts/appLayout";
import RenderRoutes from "components/renderRoutes";

export const INDEX_ROUTE = "/";
export const DONATE_ROUTE = "/donate/";
export const DEFI_PROTOCOL_ROUTE = "/defi-protocols/";

const DonateComponent = React.lazy(() => import("pages/donate"));
const DashboardComponent = React.lazy(() => import("pages/dashboard"));
const DefiProtocalComponent = React.lazy(() => import("pages/defiProtocol"));

export const APP_ROUTES = [
  {
    path: "/",
    name: "index",
    component: RenderRoutes,
    routes: [
      {
        path: "/",
        name: "layout",
        component: AppLayout,
        routes: [
          {
            exact: true,
            name: "dashboard",
            path: INDEX_ROUTE,
            component: DashboardComponent,
          },
          {
            exact: true,
            name: "donate",
            path: DONATE_ROUTE,
            component: DonateComponent,
          },
          {
            exact: true,
            name: "defi-protocols",
            path: DEFI_PROTOCOL_ROUTE,
            component: DefiProtocalComponent,
          },
        ],
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const APP_SIDEBAR_ITEMS = [
  {
    key: "dashboard",
    label: "Dashboard",
    route: INDEX_ROUTE,
    icon: <AppstoreOutlined />,
  },
  {
    key: "suggestion",
    label: "Suggestion",
    icon: <CustomerServiceOutlined />,
  },
  {
    key: "donation",
    label: "Donate",
    route: DONATE_ROUTE,
    icon: <MoneyCollectOutlined />,
  },
  {
    key: "defi-protocals",
    label: "Defi Protocols",
    route: DEFI_PROTOCOL_ROUTE,
    icon: <FileOutlined />,
  },
];
