import axios from "axios";
import { useEffect, useState } from "react";
import { Layout, Menu, Row, Col, Button } from "antd";
import { matchPath, withRouter, useLocation } from "react-router-dom";

import { APP_SIDEBAR_ITEMS } from "routes";

import logo from "assets/logo.svg";
// import bncOnboard from "utils/bnc_onboard";
import RenderRoutes from "components/renderRoutes";

import "./style.scss";

const { Header, Content, Sider } = Layout;

const AppLayout = ({ history, routes }) => {
  const { pathname } = useLocation();

  const [selectedMenu, setSelectedMenu] = useState({});
  const [walletAddress, setWalletAddress] = useState();

  useEffect(() => {
    APP_SIDEBAR_ITEMS.forEach((item) => {
      const matchedPath = matchPath(pathname, {
        exact: true,
        path: item.route,
      });
      if (matchedPath) {
        if (selectedMenu.key !== item.key) {
          setSelectedMenu(item);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    // const currentState = bncOnboard.getState();
    // console.log(currentState);
  }, []);

  useEffect(() => {
    if (walletAddress) {
      const baseUrl = "https://api.debank.com/";
      const allUrl = `${baseUrl}token/balance_list?user_addr=${walletAddress}&is_all=true`;

      axios.get(allUrl).then((response) => {
        console.log(response);
      });

      console.log(walletAddress);
    }
  }, [walletAddress]);

  function onMenuItemClick(item) {
    history.push(item.route);
  }

  function onConnectWallet() {
    // const currentState = bncOnboard.getState();
    // if (currentState.address) {
    //   setWalletAddress(currentState.address);
    // } else {
    //   bncOnboard.walletSelect().then((response) => {
    //     bncOnboard.walletCheck().then((response) => {
    //       const newState = bncOnboard.getState();
    //       setWalletAddress(newState.address);
    //     });
    //   });
    // }
  }

  return (
    <Layout className="app-layout">
      <Sider width={240}>
        <div className="logo" style={{ backgroundImage: `url(${logo})` }} />
        <Menu theme="dark" mode="inline" selectedKeys={[selectedMenu.key]}>
          {APP_SIDEBAR_ITEMS.map((item) => {
            return (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={() => onMenuItemClick(item)}
              >
                {item.label}
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout>
        <Header>
          <Row justify="end" className="w-full">
            <Col>
              <Button
                type="primary"
                size="large"
                onClick={() => onConnectWallet()}
              >
                Connect wallet
              </Button>
            </Col>
          </Row>
        </Header>
        <Content>
          <RenderRoutes routes={routes} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRouter(AppLayout);
