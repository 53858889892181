import { Switch, BrowserRouter } from "react-router-dom";

import { APP_ROUTES } from "routes";

import RouteWithSubRoutes from "./components/routeWithSubRoutes";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        {APP_ROUTES.map((route, i) => {
          return <RouteWithSubRoutes key={i} {...route} />;
        })}
      </Switch>
    </BrowserRouter>
  );
};

export default App;
